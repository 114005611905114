import React from 'react'

export function ButtonGroup ({ changeValue, actionValue, action, actionLabel }) {
  return (
    <div className='buttons'>
      <button
        className={actionValue === 0 ? 'active' : ''}
        onClick={() => changeValue(action, 0)}
        aria-label={
          'Button for currently having high-efficiency ' + actionLabel
        }
        title={'Button for currently having high-efficiency ' + actionLabel}
      >
        Yes
      </button>
      <button
        className={actionValue === 1 ? 'active' : ''}
        aria-label={'Button for not having high-efficiency ' + actionLabel}
        title={'Button for not having high-efficiency ' + actionLabel}
        onClick={() => changeValue(action, 1)}
      >
        No
      </button>
      <button
        aria-label={'Button for having some high-efficiency ' + actionLabel}
        title={'Button for having some high-efficiency ' + actionLabel}
        className={actionValue === 2 ? 'active' : ''}
        onClick={() => changeValue(action, 2)}
      >
        Some
      </button>
      <button
        aria-label={
          'Button for not sure if you have high-efficiency ' + actionLabel
        }
        title={'Button for not sure if you have high-efficiency ' + actionLabel}
        className={actionValue === 3 ? 'active' : ''}
        onClick={() => changeValue(action, 3)}
      >
        Unsure
      </button>
    </div>
  )
}

export function ButtonGroupWithOptions ({ changeValue, action, actions, actionValue, question }) {
  return (
    <div className='buttons'>
      {actions.map((val, index) => (
        <button
          className={actionValue === val.actionValue ? 'active' : ''}
          aria-pressed={actionValue === val.actionValue}
          onClick={() => changeValue(action, val.actionValue)}
          aria-label={'Button for question: "'+ question +'" with value ' + val.actionLabel}
          title={'Button for question: "'+ question +'" with value ' + val.actionLabel}
          key={val + index}
        >
          {val.actionLabel}
        </button>
      ))}
    </div>
  )
}
