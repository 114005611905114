import React from 'react'
import { Input } from './Input'
import { ButtonGroup, ButtonGroupWithOptions } from './ButtonGroup'
import mainLogo from '../icons/logo.png'
import mainLogo2 from '../icons/logo2.png'
import waterDude3 from '../icons/waterDude3.png'
import waterDude2 from '../icons/waterDude2.png'
import waterDude1 from '../icons/waterDude1.png'
import waterDude4 from '../icons/waterDude4.png'

export function QuestionSection ({
  questions,
  changeButtons,
  changeValue,
  changeOption,
  imgSrc,
  title,
  didYouKnow,
  step,
  stepLength
}) {
  return (
    <div className='section'>
      {step !== 0 && (
        <div className='titleSection'>
          <h1 className='highlight' id="h1ID" tabIndex='0' aria-label={title}>
            {title}
          </h1>

          {step !== stepLength - 1 && (
            <>
              <img
                className='mainLogo big'
                src={mainLogo}
                alt='BayREN Water Upgrades Save Logo'
              />
              <img
                className='mainLogo little'
                src={mainLogo2}
                alt='BayREN Water Upgrades Save Logo'
              />
            </>
          )}
        </div>
      )}
      <div className='questionSection'>
        {step !== 0 && typeof didYouKnow !== 'undefined' && (
          <div className='didYouKnowBox'>
            {step === 1 && (
              <img
                className='waterDude'
                src={waterDude1}
                alt='Water Character'
              />
            )}
            {step === 2 && (
              <img
                className='waterDude'
                src={waterDude2}
                alt='Water Character'
              />
            )}
            {step === 3 && (
              <img
                className='waterDude'
                src={waterDude3}
                alt='Water Character'
              />
            )}
            {step === 4 && (
              <img
                className='waterDude'
                src={waterDude4}
                alt='Water Character'
              />
            )}
            <h4 className='didYouKnow' tabIndex={0}>
              <div className='highlight'>Did you know?</div>
              {didYouKnow}
            </h4>
          </div>
        )}
        <div className='questions'>
          {questions.map((val, index) => (
            <SubQuestionSection
              questions={val}
              changeButtons={changeButtons}
              changeValue={changeValue}
              changeOption={changeOption}
              key={val + index}
              title={title}
            />
          ))}
        </div>
        <div>
          <img
            src={imgSrc[0]}
            className={'image icons' + (step === 0 ? ' home' : '')}
            alt={imgSrc[1]}
          />
        </div>
      </div>
    </div>
  )
}

function SubQuestionSection ({
  questions,
  changeButtons,
  changeValue,
  changeOption,
  title
}) {
  if (
    questions.length === 1 &&
    typeof questions[0].classValue !== 'undefined' &&
    questions[0].classValue === 'questionButtonSection'
  ) {
    return (
      <>
        <div className='question' tabIndex="0">
          {questions[0].actionLabel}

          {questions[0].notSure !== null &&
            typeof questions[0].notSure !== 'undefined' && (
              <>
                <div className='italics'>{questions[0].notSure}</div>
              </>
            )}
        </div>
        <ButtonGroup
          changeValue={changeButtons}
          actionValue={questions[0].actionValue}
          action={questions[0].action}
          actionLabel={questions[0].actionHalfLabel}
        />
        {questions[0].caption && (
          <div className='caption italics'>{questions[0].caption}</div>
        )}
      </>
    )
  } else if (questions.length === 1 && questions[0].actionLabel === '') {
    return (
      <>
        <h1 className='highlight center' tabIndex='0' aria-label={title}>
          {title}
        </h1>
        <Input
          changeValue={changeValue}
          actionValue={questions[0].actionValue}
          action={questions[0].action}
          actionLabel={title}
          classValue={'input'}
          min={questions[0].min}
          max={questions[0].max}
        />
      </>
    )
  } else {
    return (
      <>
        {questions.map((question, index) => (
          <SubQuestion
            question={question}
            changeValue={changeOption}
            key={index + '_subQuestion'}
          />
        ))}
      </>
    )
  }
}

function SubQuestion ({ question, changeValue }) {
  return (
    <>
      <div className={'question'} tabIndex={0}>
        {question.actionLabel}
        {question.notSure !== null && typeof question.notSure !== 'undefined' && (
          <>
            <br />
            <div className='italics'>{question.notSure}</div>
          </>
        )}
      </div>
      <ButtonGroupWithOptions
        changeValue={changeValue}
        action={question.action}
        actions={question.actions}
        actionValue={question.actionValue}
        question={question.actionLabel}
      />
    </>
  )
}
