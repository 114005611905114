import './App.css'
import mainLogo2 from './icons/logo2.png'
import sink from './icons/sink.svg'
import kitchen from './icons/kitchen.svg'
import bath from './icons/bath.svg'
import toilet from './icons/toilet.svg'
import waterDude0 from './icons/waterDude0.png'
import home from './icons/home.svg'
import React, { useState, useEffect } from 'react'
import { QuestionSection } from './Components/QuestionSection'
import { Results } from './Components/Results'
import { PageControl } from './Components/PageControl'
import { initializeApp } from 'firebase/app'
import { logEvent, getAnalytics } from 'firebase/analytics'
import { getFirestore, collection, addDoc } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCvENc7VbL8_BBTqPEmkUW8AKbOX9fMYOE',
  authDomain: 'water-calculator-fd984.firebaseapp.com',
  databaseURL: 'https://water-calculator-fd984-default-rtdb.firebaseio.com',
  projectId: 'water-calculator-fd984',
  storageBucket: 'water-calculator-fd984.appspot.com',
  messagingSenderId: '456252375654',
  appId: '1:456252375654:web:ee099c8b747140b52663a7',
  measurementId: 'G-TD82DGC1L6'
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const db = getFirestore(app)

function App () {
  const [people, setPeople] = useState(1)
  const [shower, setShower] = useState(0)
  const [showerLen, setShowerLen] = useState(0)
  const [flush, setFlush] = useState(0)
  const [faucet, setFaucet] = useState(0)
  const [faucetLen, setFaucetLen] = useState(0)
  const [dishes, setDishes] = useState(0)
  const [dishesLen, setDishesLen] = useState(0)

  const [showerHeads, setShowerHeads] = useState(5)
  const [heFaucets, setHeFaucets] = useState(5)
  const [heSink, setHeSink] = useState(5)
  const [heToilet, setHeToilet] = useState(5)

  const [showerGallons, setShowerGallons] = useState(0)
  const [faucetGallons, setFaucetGallons] = useState(0)
  const [toiletGallons, setToiletGallons] = useState(0)
  const [hotWater, setHotWater] = useState(0)

  const [showerGallonsWUS, setShowerGallonsWUS] = useState(0)
  const [faucetGallonsWUS, setFaucetGallonsWUS] = useState(0)
  const [toiletGallonsWUS, setToiletGallonsWUS] = useState(0)

  const [step, setStep] = useState(0)

  const GPM = 2.17 // showers
  const wusGPM = 1.5

  const GPF = 1.61 // toilets
  const wusGPF = 1

  const GPMKitchen = 1.75 //kitchen sink
  const wusKitchenGPM = 1.5

  const GPMBathroom = 1.2 //bathroom sink
  const wusBathroomGPM = 1

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [step])

  function getGPM (value, WUSgals, gals) {
    if (value === 1 || value === 3) {
      return gals
    } else if (value === 2) {
      return (gals + WUSgals) / 2
    } else {
      return WUSgals
    }
  }

  function getGPMValue (value) {
    if (value === 1 || value === 3) {
      return "no or don't know"
    } else if (value === 2) {
      return 'some'
    } else {
      return 'yes'
    }
  }

  // 0 yes, 1 no, 2 some, 3 unsure
  function calculateGallons () {
    setShowerGallons(
      people * shower * showerLen * getGPM(showerHeads, wusGPM, GPM)
    )
    setFaucetGallons(
      faucet * faucetLen * getGPM(heFaucets, wusBathroomGPM, GPMBathroom) +
        dishes * dishesLen * getGPM(heSink, wusKitchenGPM, GPMKitchen)
    )
    setToiletGallons(people * flush * getGPM(heToilet, wusGPF, GPF))

    setShowerGallonsWUS(people * shower * showerLen * wusGPM)
    setFaucetGallonsWUS(
      faucet * faucetLen * wusBathroomGPM + dishes * dishesLen * wusKitchenGPM
    )
    setToiletGallonsWUS(people * flush * wusGPF)
  }

  useEffect(() => {
    calculateGallons()
  }, [
    people,
    shower,
    showerLen,
    flush,
    faucet,
    faucetLen,
    dishes,
    dishesLen,
    showerHeads,
    heFaucets,
    heSink,
    heToilet
  ])

  function clearData () {
    setPeople(1)
    setShower(0)
    setShowerLen(0)
    setFlush(0)
    setFaucet(0)
    setFaucetLen(0)
    setDishes(0)
    setDishesLen(0)
    setShowerHeads(5)
    setHeFaucets(5)
    setHeSink(5)
    setHeToilet(5)
    setShowerGallons(0)
    setFaucetGallons(0)
    setToiletGallons(0)
    setStep(0)
    setHotWater(0)

    calculateGallons()
    logEvent(analytics, 'restarted_survey')
  }

  useEffect(() => {
    setPeople(1)
    setShower(0)
    setShowerLen(0)
    setFlush(0)
    setFaucet(0)
    setFaucetLen(0)
    setDishes(0)
    setDishesLen(0)
    setShowerHeads(5)
    setHeFaucets(5)
    setHeSink(5)
    setHeToilet(5)
    setShowerGallons(0)
    setFaucetGallons(0)
    setToiletGallons(0)
    setStep(0)
    setHotWater(0)

    calculateGallons()
  }, [])

  function changeButtons (change, value) {
    if (value < 0 || value > 3) {
      return
    }
    switch (change) {
      case 'shower':
        setShowerHeads(value)
        break
      case 'faucet':
        setHeFaucets(value)
        break
      case 'sink':
        setHeSink(value)
        break
      case 'toilet':
        setHeToilet(value)
        break
      default:
        break
    }
  }

  function changeOption (change, value) {
    switch (change) {
      case 'people':
        setPeople(value)
        break
      case 'shower':
        setShower(value)
        break
      case 'showerLen':
        setShowerLen(value)
        break
      case 'hotWater':
        setHotWater(value)
        break
      case 'flush':
        setFlush(value)
        break
      case 'faucet':
        setFaucet(value)
        break
      case 'faucetLen':
        setFaucetLen(value)
        break
      case 'dishes':
        setDishes(value)
        break
      case 'dishesLen':
        setDishesLen(value)
        break
      default:
        break
    }
  }

  function goodValue (value, min, max) {
    return value >= min && value <= max
  }

  function emptyValue (value) {
    return value === ''
  }

  function changeValue (change, value) {
    switch (change) {
      case 'people':
        goodValue(value, 1, 15) && setPeople(value)
        emptyValue(value) && setPeople('')
        break
      default:
        break
    }
  }

  const stepInfo = [
    {
      questions: [
        [
          {
            actionLabel: '',
            actionValue: people,
            action: 'people',
            notSure: null,
            min: 1,
            max: 15
          }
        ]
      ],
      title: 'How many people are in your household?',
      imgSrc: [home, 'house icon']
    },
    {
      questions: [
        [
          {
            actionLabel: 'How many showers do you take each week on average?',
            actionValue: shower,
            action: 'shower',
            notSure:
              'Not sure? Pick 6 to 8 as some two-thirds of Americans shower daily.',
            min: 1,
            max: 300,
            actions: [
              {
                actionValue: 4,
                actionLabel: 'Less than 6'
              },
              {
                actionValue: 7,
                actionLabel: '6 to 8'
              },
              {
                actionValue: 9,
                actionLabel: 'More than 8'
              }
            ]
          },
          {
            actionLabel: 'How long is your average shower?',
            actionValue: showerLen,
            action: 'showerLen',
            notSure:
              'Not sure? Pick 7 to 10 minutes as the average shower lasts 8 minutes.',
            min: 1,
            max: 60,
            actions: [
              {
                actionValue: 5,
                actionLabel: 'Less than 7 mins'
              },
              {
                actionValue: 8,
                actionLabel: '7 to 10 mins'
              },
              {
                actionValue: 11,
                actionLabel: 'More than 10 mins'
              }
            ]
          }
        ],
        [
          {
            classValue: 'questionButtonSection',
            actionLabel:
              'Do you currently have high-efficiency shower heads installed?',
            actionValue: showerHeads,
            action: 'shower',
            actionHalfLabel: 'shower heads installed',
            notSure:
              'High-efficiency showerheads use just 1.5 gallons per minute (GPM) of water compared to a 2.1 GPM flow rate for standard showerheads.'
          }
        ],
        [
          {
            actionLabel: 'How is your hot water heated?',
            actionValue: hotWater,
            action: 'hotWater',
            notSure:
              'High-efficiency showerheads reduce your need for hot water, which means less energy is needed for your water heater. That can directly translate into energy savings for you as well.',
            actions: [
              {
                actionValue: 1,
                actionLabel: 'Electric'
              },
              {
                actionValue: 2,
                actionLabel: 'Gas'
              },
              {
                actionValue: 3,
                actionLabel: 'Not Sure'
              }
            ]
          }
        ]
      ],
      title: 'SHOWERS',
      imgSrc: [bath, 'shower icon'],
      didYouKnow:
        "Using a high-efficiency showerhead can save 150 gallons/month per person. That's less work for your hot water heater meaning energy bill savings too."
    },
    {
      questions: [
        [
          {
            actionLabel: 'How many times do you flush your home toilet a day?',
            actionValue: flush,
            action: 'flush',
            notSure:
              'Not sure? The average person flushes a toilet 5 times a day.',
            min: 0,
            max: 100,
            actions: [
              {
                actionValue: 4,
                actionLabel: 'Less than 5'
              },
              {
                actionValue: 6,
                actionLabel: '5 to 7'
              },
              {
                actionValue: 8,
                actionLabel: 'More than 7'
              }
            ]
          }
        ],
        [
          {
            classValue: 'questionButtonSection',
            actionLabel:
              'Do you currently have high-efficiency toilets installed?',
            actionValue: heToilet,
            action: 'toilet',
            actionHalfLabel: 'toilets installed',
            notSure:
              'Toilets made before 1982 can use between 5 and 7 gallons per flush (GPF), while new high-efficiency toilets use just 1 GPF.'
          }
        ]
      ],
      title: 'TOILETS',
      imgSrc: [toilet, 'toilets icon'],
      didYouKnow:
        'You may be, literally, flushing away a lot of money. Toilets are typically the main source of home water use, accounting for nearly 30 percent of indoor water consumption.'
    },
    {
      questions: [
        [
          {
            actionLabel: 'How often do you use the bathroom sink each day?',
            actionValue: faucet,
            notSure:
              'In an average home, bathroom faucets are used about around eight minutes daily and use about 20 gallons.',
            action: 'faucet',
            min: 1,
            max: 50,
            actions: [
              {
                actionValue: 4,
                actionLabel: 'Less than 5'
              },
              {
                actionValue: 6,
                actionLabel: '5 to 7'
              },
              {
                actionValue: 8,
                actionLabel: 'More than 7'
              }
            ]
          },
          {
            actionLabel:
              'On average, how long does the faucet run during each use?',
            actionValue: faucetLen,
            notSure:
              'Turning off water while doing other tasks can save a lot of water.',
            action: 'faucetLen',
            min: 1,
            max: 60,
            actions: [
              {
                actionValue: 1,
                actionLabel: 'Less than 2 mins'
              },
              {
                actionValue: 2,
                actionLabel: '2 to 3 mins'
              },
              {
                actionValue: 4,
                actionLabel: 'More than 3 mins'
              }
            ]
          }
        ],
        [
          {
            classValue: 'questionButtonSection',
            actionLabel:
              'Do you have high-efficiency faucets installed in your bathroom?',
            actionValue: heFaucets,
            action: 'faucet',
            actionHalfLabel: 'faucets installed in your bathroom',
            notSure:
              'A typical bathroom faucet uses about 1.2 gallons per minute. A new, high-efficiency one uses just 1 GPM, an immediate 20% savings in water usage.'
          }
        ]
      ],
      title: 'BATHROOM SINKS',
      imgSrc: [sink, 'faucets icon'],
      didYouKnow:
        'In a four person household, if you run the faucet while brushing your teeth twice a day, you will waste 32 gallons of water a day.'
    },
    {
      questions: [
        [
          {
            actionLabel: 'How often do you wash dishes by hand each day?',
            actionValue: dishes,
            action: 'dishes',
            min: 0,
            max: 100,
            notSure:
              'Not sure? Be sure to include any pre-rinsing you might do even if you have a dishwasher.',
            actions: [
              {
                actionValue: 2,
                actionLabel: 'Less than 4'
              },
              {
                actionValue: 5,
                actionLabel: '4 to 6'
              },
              {
                actionValue: 7,
                actionLabel: 'More than 6'
              }
            ]
          },
          {
            actionLabel:
              'On average, how many minutes does the sink run during each use?',
            actionValue: dishesLen,
            action: 'dishesLen',
            min: 1,
            max: 60,
            notSure:
              'Turning off water while doing other tasks can save a lot of water.',
            actions: [
              {
                actionValue: 1,
                actionLabel: 'Less than 2 mins'
              },
              {
                actionValue: 2,
                actionLabel: '2 to 3 mins'
              },
              {
                actionValue: 4,
                actionLabel: 'More than 3 mins'
              }
            ]
          }
        ],
        [
          {
            classValue: 'questionButtonSection',
            actionLabel:
              'Do you currently have high-efficiency sink faucets already installed?',
            actionValue: heSink,
            action: 'sink',
            actionHalfLabel: 'sink faucets installed',
            notSure:
              'Existing typical kitchen faucets use about 1.75 gallons per minute. A new, high-efficiency one drops that down to 1.5 GPM.'
          }
        ]
      ],
      title: 'KITCHEN SINKS',
      imgSrc: [kitchen, 'dishes icon'],
      didYouKnow:
        'Washing dishes with the water running uses about 15 gallons every five minutes.'
    },
    { questions: [], title: 'Results' }
  ]

  useEffect(() => {
    if (step === stepInfo.length - 1) {
      logEvent(analytics, 'results_page')

      sendResults(
        {
          totalGallons: (
            (showerGallons + faucetGallons + toiletGallons) *
            52.14
          ).toFixed(0),
          saveGallons: (
            (showerGallons +
              faucetGallons +
              toiletGallons -
              (showerGallonsWUS + faucetGallonsWUS + toiletGallonsWUS)) *
            52.14
          ).toFixed(0),
          people: people,
          hotWater: hotWater === 2 ? 'Gas' : 'Electric',
          showerEfficency: getGPMValue(showerHeads),
          bathroomEfficency: getGPMValue(heFaucets),
          sinkEfficency: getGPMValue(heSink),
          toiletEfficency: getGPMValue(heToilet),
          showerGallons: (showerGallons * 52.14).toFixed(0),
          saveShowerGallons: (
            (showerGallons - showerGallonsWUS) *
            52.14
          ).toFixed(0),
          faucetGallons: (faucetGallons * 52.14).toFixed(0),
          saveFaucetGallons: (
            (faucetGallons - faucetGallonsWUS) *
            52.14
          ).toFixed(0),
          toiletGallons: (toiletGallons * 52.14).toFixed(0),
          saveToiletGallons: (
            (toiletGallons - toiletGallonsWUS) *
            52.14
          ).toFixed(0),
          date: Date.now(),
          dateString: Date().toString()
        },
        db
      )
    } else {
      logEvent(analytics, 'started_step_' + step)
    }
  }, [step])

  async function sendResults (data, db) {
    await addDoc(collection(db, 'results'), data)
  }

  return (
    <div className='App'>
      <div className='sectionHolder'>
        {step === 0 && (
          <>
            <div className='header'>
              <div className='headerSection'>
                <div>
                  <img
                    className='mainLogo'
                    src={mainLogo2}
                    alt='BayREN Water Upgrades Save Logo'
                  />
                </div>
                <h1 tabIndex='0' id='h1ID'>
                  <p className='headerText' tabIndex={0}>
                    How much <a className='highlight'>Water & Energy</a> could
                    you save?
                  </p>
                </h1>
              </div>
            </div>
            <div className='section'>
              <div className='didYouKnowBox'>
                <img
                  className='waterDude'
                  src={waterDude0}
                  alt='Water Character'
                />
                <div
                  className='didYouKnow instructions'
                  tabIndex='0'
                  aria-label='Answer a few quick questions about your water & energy use to see how much you can save with Water Upgrades Save'
                  title='Answer a few quick questions about your water & energy use to see how much you can save with Water Upgrades Save'
                >
                  <p>
                    Answer a few quick questions about your water & energy use
                    to see how much you can save with{' '}
                    <a className='highlight'>Water Upgrades Save</a>!
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {step !== stepInfo.length - 1 && (
          <QuestionSection
            {...stepInfo[step]}
            changeValue={changeValue}
            changeButtons={changeButtons}
            changeOption={changeOption}
            step={step}
            stepLength={stepInfo.length}
          />
        )}
        {step !== 0 && step === stepInfo.length - 1 && (
          <Results
            results={[
              {
                actionValue: showerGallons,
                actionValueWUS: showerGallonsWUS,
                actionLabel: 'Shower',
                active: step === 1
              },
              {
                actionValue: faucetGallons,
                actionValueWUS: faucetGallonsWUS,
                actionLabel: 'Sink',
                active: step === 4 || step === 3
              },
              {
                actionValue: toiletGallons,
                actionValueWUS: toiletGallonsWUS,
                actionLabel: 'Toilet',
                active: step === 2
              }
            ]}
            imgSrcs={[
              [bath, 'shower icon'],
              [sink, 'faucet icon'],
              [toilet, 'toilet icon']
            ]}
            minify={step !== stepInfo.length - 1}
            saveGallons={(
              (showerGallons +
                faucetGallons +
                toiletGallons -
                (showerGallonsWUS + faucetGallonsWUS + toiletGallonsWUS)) *
              52.14
            ).toFixed(0)}
            totalGallons={(
              (showerGallons + faucetGallons + toiletGallons) *
              52.14
            ).toFixed(0)}
            hotWater={hotWater}
            analytics={analytics}
            logEvent={logEvent}
          />
        )}
      </div>
      <PageControl
        setStep={setStep}
        step={step}
        clearData={clearData}
        stepLength={stepInfo.length}
        title={stepInfo[step].title}
      />
    </div>
  )
}

export default App
