import React from 'react'

export function Input ({
  changeValue,
  actionValue,
  action,
  actionLabel,
  classValue,
  min,
  max
}) {
  return (
    <>
      <div className={classValue}>
        <div
          tabIndex='0'
          aria-label={"Decrement '" + actionLabel + "'" + ". Current value is "+ actionValue}
          title={"Decrement '" + actionLabel + "'"}
          className='decrementButton'
          onClick={() => changeValue(action, actionValue - 1)}
          onKeyDown={(e) => e.code === "Enter" && changeValue(action, actionValue - 1)}
        >
          -
        </div>
        <div
          className={
            'inputValue mask ' +
            (actionValue > (max - min) / 2
              ? 'mask75'
              : actionValue < (max - min) / 4
              ? 'mask25'
              : 'mask50')
          }
          data-selected={actionValue}
          tabIndex='0'
          aria-label={"Value for '" + actionLabel + "'" + ". Current value is "+ actionValue}
          title={"Value for '" + actionLabel + "'"}
          id='household-stepper'
        >
          {actionValue}
        </div>

        <div
          tabIndex='0'
          aria-label={"Increment '" + actionLabel + "'"}
          title={"Increment '" + actionLabel + "'"}
          className='incrementButton'
          onClick={() => changeValue(action, actionValue + 1)}
          onKeyDown={(e) => e.code === "Enter" &&  changeValue(action, actionValue + 1)}
        >
          +
        </div>
      </div>
      <div
        aria-live='assertive'
        className='hidden'
        id='household-stepper-status-target'
      >
        {'People in your household updated to ' + actionValue}
      </div>
    </>
  )
}
