import React, { useEffect } from 'react'

export function PageControl ({ step, setStep, clearData, stepLength, title }) {
  useEffect(() => {
    setTimeout(() => {
      let element = document.getElementById('h1ID')
      if (element) {
        element.focus()
      }
    }, 50)
  }, [step])

  return (
    <div className='pageControlParent'>
      <div
        className={step === 0 || step === stepLength - 1 ? '' : 'pageControl'}
      >
        <div className='controlButtons'>
          {step !== 0 && (
            <button onClick={() => setStep(step - 1)} aria-label='Back'>
              BACK
            </button>
          )}
          {step !== 0 && step !== stepLength - 1 && (
            <Buttons
              count={stepLength}
              currentStep={step}
              setStep={setStep}
              title={title}
            />
          )}
          {step === stepLength - 1 && (
            <button onClick={clearData} aria-label='Restart'>
              RESTART
            </button>
          )}
          {step !== stepLength - 1 && (
            <button
              onClick={() => setStep(step + 1)}
              aria-label={step === 0 ? 'Start' : 'Next'}
            >
              {step === 0 ? 'START' : 'NEXT'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

function Buttons ({ count, currentStep, setStep, title }) {
  const rows = []
  for (let i = 0; i < count; i++) {
    rows.push(
      <div
        key={i}
        className={currentStep === i ? 'active' : ''}
        onClick={() => setStep(i)}
      ></div>
    )
  }
  return (
    <div className='buttonSteps'>
      {title}
      <div className='steps'>{rows}</div>
    </div>
  )
}
