import React from 'react'
import mainLogo from '../icons/logo.png'
import mainLogo2 from '../icons/logo2.png'
import waterDudeRes from '../icons/waterDudeRes.png'
import tab from '../icons/tab.svg'

export function Results ({
  results,
  imgSrcs,
  saveGallons,
  totalGallons,
  hotWater, 
  analytics,
  logEvent
}) {
  let hotWaterCalc = 0
  let hotWaterCalcSave = 0
  if (hotWater !== 2) {
    hotWaterCalc = (totalGallons * 41.1 * 8.33) / 0.92 / 3412
    hotWaterCalcSave = (saveGallons * 41.1 * 8.33) / 0.92 / 3412
  } else {
    hotWaterCalc = (totalGallons * 41.1 * 8.33) / 0.92 / 100000
    hotWaterCalcSave = (saveGallons * 41.1 * 8.33) / 0.65 / 100000
  }
  function numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function openLink() {
    window.open('https://www.bayren.org/waterupgradessave#learnmore', '_blank');
    logEvent(analytics, "opened_bayren")
  }

  return (
    <div className='section results'>
      <div className='resFlex'>
        <h1 className='highlight' id='h1ID' tabIndex='0' aria-label='Your Results'>
          YOUR RESULTS
        </h1>

        <>
          <img
            className='mainLogo big'
            src={mainLogo}
            alt='BayREN Water Upgrades Save Logo'
          />
          <img
            className='mainLogo little'
            src={mainLogo2}
            alt='BayREN Water Upgrades Save Logo'
          />
        </>
      </div>

      <div className='didYouKnowBox'>
        <img className='waterDude' src={waterDudeRes} alt='Water Character' />

        <div className='didYouKnow instructions' tabIndex={0}>
          <h2 className='highlight'>
            {saveGallons > 10 && (
              <p>
                Save{' '}
                <a className='highlight'>
                  {numberWithCommas(saveGallons)} gallons/year
                </a>{' '}
                with Water Upgrades Save! That's enough water to fill{' '}
                <a className='highlight'>
                  {numberWithCommas((saveGallons / 450).toFixed(1))}
                </a>{'  hot tubs.'}
              </p>
            )}
            {saveGallons <= 10 && (
              <>
                <p>
                  You are doing a great job! Check out{' '}
                  <a href='http://bayren.org'>bayren.org</a> for more ways to
                  save money and energy!
                </p>
              </>
            )}
          </h2>
        </div>
      </div>

      <div className='resultFlex'>
        <>
          {results.map((result, index) => (
            <Result
              {...result}
              imgSrc={imgSrcs[index]}
              key={index + '_Results'}
              otherText={
                index === 0 && hotWaterCalcSave < hotWaterCalc
                  ? 'and save ' +
                    numberWithCommas(hotWaterCalcSave.toFixed(0)) +
                    ' ' +
                    (hotWater !== 2 ? 'kWh' : 'therms') +
                    '/year in energy too!'
                  : ''
              }
            />
          ))}
        </>
      </div>

      <div className='questionSection'>
        <button
          onClick={openLink}
          title={'Learn More'}
        >
          Learn More
          <img src={tab} alt='' />
        </button>
      </div>
    </div>
  )
}

function Result ({
  actionValue,
  actionValueWUS,
  actionLabel,
  imgSrc,
  otherText
}) {
  function numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return (
    <div
      tabIndex='0'
      aria-label={
        actionLabel +
        ': ' +
        numberWithCommas((actionValue * 52.14).toFixed(0)) +
        ' gallons per year'
      }
      title={
        actionLabel +
        ': ' +
        numberWithCommas((actionValue * 52.14).toFixed(0)) +
        ' gallons per year'
      }
    >
      <div className='resultHolder'>
        <img src={imgSrc[0]} className='image icons' alt={imgSrc[1]} />
        <div className='res'>
          <div className='highlight'>Your Current {actionLabel} Usage:</div>
          <div className='resultValue'>
            {numberWithCommas((actionValue * 52.14).toFixed(0))} gallons/year
          </div>
        </div>
      </div>
      {actionValueWUS < actionValue && (
        <div>
          <div className='resultHolder outlineBox3'>
            <div className='res'>
              <div className='highlight'>With Water Upgrades Save</div>
              <div className='resultValue resultValueFunnel'>
                save{' '}
                {numberWithCommas(
                  (actionValue * 52.14).toFixed(0) -
                    (actionValueWUS * 52.14).toFixed(0)
                )}{' '}
                gallons/year
              </div>
              <div>{otherText}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
